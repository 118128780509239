import React from "react";
import { Link } from "react-router-dom";
import CreateProfileModal from "../../pages/createProfile/CreateProfileModal";

// STYLES
import classes from "./authPages.module.css";

export default function authPages({
  image,
  text,
  login,
  setNumber,
  handleSubmit,
  userDigit,
  number,
  version,
}) {
  // console.log('userDigit', userDigit, number)

  return (
    <div className={classes.authPages}>
      <div className={classes.imgContainer}>
        <img src={image} alt="" />
      </div>
      <h2>{text}</h2>

      {/* LOGIN */}
      {login && (
        <form className={classes.LoginForm} onSubmit={(e) => handleSubmit(e)}>
          <input
            type="number"
            name="login"
            placeholder={`Enter your ${
              version === "mtn" ? "mtn" : "glo"
            } phone number`}
            autoComplete="off"
            // value={number}
            onChange={(e) => setNumber(e.target.value)}
            // set input to users value if users phone number is not available.
            // onChange={(e) => setNumber(userDigit ? userDigit : e.target.value)}
          />
          <button type="submit">
            <div className={classes.LoginBtn}>Login</div>
          </button>
        </form>
      )}

      {/* SUBSCRIBE */}
      {!login && (
        <Link to="/subscription-plans">
          <div className={classes.SubscribeBtn}>
            <span>Subscribe</span>
          </div>
        </Link>
      )}
    </div>
  );
}
