import React from 'react'

//STYLES
import classes from "./badges.module.css";

// COMPONENTS
import TopNav from "../../components/TopNav/TopNav";
import giftBox from '../../assets/gift-box.png'
import BadgeCard from '../../components/badgeCard/badgeCard';
import { useFetchBadges } from '../../hooks/fetch';



const Badges = () => {
  const userInfo = JSON.parse(sessionStorage.getItem("ccAuth"));
  const { data } = useFetchBadges(userInfo.userPassport);

  console.log('Badgesss', data)

  return (
    <div className={classes.container}>
      <TopNav />
      <div className={classes.titleWrap}>
        <span>My Badges</span>
        <img src={giftBox} alt="gift box" />
      </div>

      <div className='whiteContainer'>
        <div  className={classes.badgesWrap}>
          {data?.data?.map((badge)=>(
            <BadgeCard data={badge}/>
          ))}
        </div>
      </div>
    </div>
  )
}

export default Badges