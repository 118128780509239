import React from "react";

//STYLES
import classes from "./newBadgeCongratulation.module.css";

// IMAGES
import giftBox from "../../assets/gift-box.png";
import closeBtn from "../../assets/close-btn-round.png";
import { Link } from "react-router-dom";

// COMPONENETS

const NewBadgeCongratulation = ({ setIsNewBadge }) => {
  return (
    <div className={classes.congrats}>
      <img
        src={closeBtn}
        alt="close button"
        className={classes.close}
        onClick={() => setIsNewBadge(false)}
      />
      <div className={classes.container}>
        <h3>Congratulations!</h3>
        <h4>You won a new badge and unlocked a new reward</h4>
        <h2>Science Explorer</h2>
        <img src={giftBox} alt="" width="300px" height="200px" />
        <Link to="/badges">
          <button>See Rewards</button>
        </Link>
      </div>
    </div>
  );
};

export default NewBadgeCongratulation;
