import React, { useContext } from "react";
import { NavLink, useLocation, useParams } from "react-router-dom";

// STYLES
import classes from "./BottomNav.module.css";

// IMAGES
import ProfileIcon from "../../assets/Profile-Icon2.png";
import GamesIcon from "../../assets/games-icon.png";
import TrophyIcon from "../../assets/trophy-icon.png";
import { VersionContext } from "../../context/VersionContext";

export default function BottomNav() {
  const { version } = useContext(VersionContext);
  const { pathname } = useLocation();

  //MAKE ARRAY
  const array = pathname.split("/");

  //GET THE LAST SEGMENT OF THE URL
  const lastsegment = array[array.length - 1];
  console.log({ array, pathname, lastsegment });
  return (
    <>
      {!pathname.includes(`/games/${lastsegment}`) && (
        <nav className={classes.BottomNav}>
          <ul>
            <li>
              <NavLink
                to={`/${version}/profile`}
                className={({ isActive }) =>
                  isActive ? classes.active : classes.inactive
                }
              >
                <img src={ProfileIcon} alt="profile nav" />
              </NavLink>
            </li>

            <li>
              <NavLink
                to={`/${version}/games`}
                className={({ isActive }) =>
                  isActive ? classes.active : classes.inactive
                }
              >
                <img src={GamesIcon} alt="games nav" />
              </NavLink>
            </li>

            <li>
              <NavLink
                to={`/${version}/leaderboard`}
                className={({ isActive }) =>
                  isActive ? classes.active : classes.inactive
                }
              >
                <img src={TrophyIcon} alt="leaderboard nav" />
              </NavLink>
            </li>
          </ul>
        </nav>
      )}
    </>
  );
}
