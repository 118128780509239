import React, { useContext } from "react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

// STYLES
import classes from "./TopNav.module.css";

// IMAGES
import KidsLogo from "../../assets/9ijaKids-Logo.png";
import GloLogo from "../../assets/glo-logo.png";
import MtnLogo from "../../assets/mtn-logo.png";
import astronaut from "../../assets/astronaut.png";
import { Avatar } from "../../Data";
import { useFetchProfile } from "../../hooks/fetch";
import { VersionContext } from "../../context/VersionContext";

export default function TopNav({ verse, showLogo = true }) {
  const { version } = useContext(VersionContext);
  const userInfo = JSON.parse(sessionStorage.getItem("ccAuth"));
  const { data: profile } = useFetchProfile(userInfo?.userPassport);
  const [isAuth, setIsAuth] = useState("");
  const { pathname } = useLocation();
  const [isMenu, setIsMenu] = useState(false);
  const navigate = useNavigate();

  //FIND USER AVATAR IN AVATAR DATA
  const userAvatar = Avatar.find(
    (avatar) => avatar.title == profile?.data?.avatar
  );

  // CHECK USERS AUTH STATUS AS THEY MOVE THROUGH THE APPLICATION
  useEffect(() => {
    const auth = sessionStorage.getItem("ccAuth");
    setIsAuth(auth);
  }, [pathname]);

  const logOut = () => {
    sessionStorage.clear();
    if (version === "mtn") {
      navigate(`/${version}`);
    } else {
      navigate(`/${version}/login`);
    }
  };

  return (
    <>
      <nav className={classes.TopNav}>
        <ul>
          {!isAuth && (
            <>
              <li>
                <img src={KidsLogo} alt="9ijakids logo" />
              </li>

              <li>
                {showLogo && (
                  <img
                    src={version === "mtn" ? MtnLogo : GloLogo}
                    alt={version === "mtn" ? "MtnLogo" : "Glo logo"}
                    style={{ height: "46px" }}
                  />
                )}
                {/* <img src={GloLogo} alt="Glo logo" /> */}
              </li>
            </>
          )}

          {isAuth && (
            <div className={classes.userProfile}>
              {profile?.data?.userInfo?.display_name === null ? (
                <span> Hello </span>
              ) : (
                <span> {profile?.data?.userInfo?.display_name} </span>
              )}
              <img
                src={
                  profile?.data?.userInfo?.avatar
                    ? profile?.data?.userInfo?.avatar
                    : astronaut
                }
                alt={
                  profile?.data?.userInfo?.avatar
                    ? profile?.data?.userInfo?.avatar
                    : "Profile"
                }
                className={classes.avatar}
              />
            </div>
          )}

          <li>
            <div className={classes.burger}>
              <div onClick={() => setIsMenu(!isMenu)}>
                <span></span>
                <span></span>
                <span></span>
              </div>

              {isMenu && (
                <div className={classes.burgerMenu}>
                  {/* <div
                    onClick={() => navigate(`/${version}/subscription-plans`)}
                  >
                    Subscriptions
                  </div> */}
                  <div onClick={() => navigate(`/${version}/faq`)}>FAQs</div>
                  {isAuth ? (
                    <div onClick={() => logOut()}>Logout</div>
                  ) : (
                    <div onClick={() => navigate(`/${version}/login`)}>
                      Login
                    </div>
                  )}
                </div>
              )}
            </div>
          </li>
        </ul>
      </nav>

      <div className={classes.banner}>
        <span> For support or enquiries, please contact: 09157504476</span>
      </div>
    </>
  );
}
