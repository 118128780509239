import React from "react";
import { GiPadlock } from "react-icons/gi";

//STYLES
import classes from "./badgeCard.module.css";

// IMAGES
import giftBox from "../../assets/gift-box.png";

const BadgeCard = ({ data }) => {
  console.log('BADGECARD', data)
  return (
    <div className={classes.rewardWrap}>
      {data.value < 14  && 
      <div 
        className={classes.overlay}> 
        <GiPadlock size={48} style={{zIndex:100}} color="white" /> 
        </div>       
      }
     <div className={classes.badge}>
        <img src={data?.badge_image ? data?.badge_image : giftBox}alt="avatar" className={classes.avatar} />
        <div className={classes.textWrap}>
          <h3>{data?.badge_name}</h3>
          <p>{data?.description}</p>

          <div className={classes.progressBar}>
            <div
                className={classes.progress}
                style={{
                  backgroundColor: "green",
                  width:
                    data.total === 15 && data.total === data.value
                      ? `${data?.total - 5}0%`
                      : data.total === 20 && data.total === data.value
                      ? `${data?.total - 10}0%`
                      :  data?.total === 20 && data?.value < data?.total ?
                         `${data?.value }%`
                      : `${data?.value }0%`
                }}
              >
              <span
                style={{
                  marginRight: "5px",
                  color: "white",
                  fontWeight: "bold",
                }}
              >
                {data?.value}/{data?.total}
              </span>
            </div>
          </div>
        </div>
    </div>
    
    </div>
  );
};

export default BadgeCard;
