import React from "react";

// STYLES
import classes from "./CreateProfileModal.module.css";

// IMAGES
import CreateProfileImg from "../../assets/createProfileModalImg.png";
import { Link } from "react-router-dom";

export default function CreateProfileModal({handleModal}) {
  return (
    <div className={classes.CreateProfileModal}>
      <div className={classes.CreateProfileContainer}>
        <h2>You have not created a profile</h2>
        <img src={CreateProfileImg} alt="create profile" />
        <Link onClick={handleModal} to="/create-profile" className={classes.CreateBtn}>
          <span>Create Profile</span>
        </Link>
        <div>
          <span onClick={handleModal}>Skip</span>
          <span onClick={handleModal}>Remind me later</span>
        </div>
      </div>
    </div>
  );
}
