import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams, NavLink } from "react-router-dom";
import { AiOutlineCloseCircle } from "react-icons/ai";
import TrophyIcon from "../../assets/trophy-icon.png";
// STYLES
import classes from "./GamePlay.module.css";

//IMAGES
import close from "../../assets/Close-btn.png";

// COMPONENTS
import { useFetchGameURL } from "../../hooks/fetch";
import Badges from "../badges/badges";
import NewBadgeCongratulation from "../../components/newBadgeCongratulation/newBadgeCongratulation";
import { VersionContext } from "../../context/VersionContext";

export default function GamePlay() {
  const { version } = useContext(VersionContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { gameId } = useParams();
  const [appleMode, setAppleMode] = useState(false);
  const [isNewBadge, setIsNewBadge] = useState(true);
  const [fetchParams, setFetchParams] = useState({
    userPassport: "",
    gameId: "",
  });
  const { data } = useFetchGameURL(fetchParams);

  useEffect(() => {
    const userInfo = JSON.parse(sessionStorage.getItem("ccAuth"));
    setFetchParams({
      userPassport: userInfo.userPassport,
      gameId: gameId,
      type: version !== "glo" && "quiz",
    });
  }, [gameId]);

  //MAKE ARRAY
  const array = pathname.split("/");

  //GET THE LAST SEGMENT OF THE URL
  const lastsegment = array[array.length - 1];

  //CLOSE GAME AND GO BACK TO GAME PAGE
  const handleClose = () => {
    navigate(-1);
  };

  // (A) LOCK SCREEN ORIENTATION TO LANDSCAPE IF ON GAME PLAY PAGE
  useEffect(() => {
    var isIOS =
      /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;

    var isAndroid = navigator.userAgent.toLowerCase().indexOf("android") > -1;

    if (isIOS) {
      setAppleMode(true);
    } else if (isAndroid) {
      const lock = () => {
        // (A1) GO INTO FULL SCREEN FIRST
        let de = document.documentElement;
        if (de.requestFullscreen) {
          de.requestFullscreen();
        } else if (de.mozRequestFullScreen) {
          de.mozRequestFullScreen();
        } else if (de.webkitRequestFullscreen) {
          de.webkitRequestFullscreen();
        } else if (de.msRequestFullscreen) {
          de.msRequestFullscreen();
        }

        // (A2) THEN LOCK ORIENTATION
        //    window.screen.orientation.lock("landscape");
      };

      sessionStorage.setItem("orientation", "locked");
      lock();
    }

    //NOTE: SEE APP.JS TO FIND UNLOCK ORIENTATION FUNCTION
  }, [pathname, data]);

  return (
    <div className={classes.gameplay}>
      <div className={classes.titleWrap}>
        <h3>{data?.game.game_title}</h3>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <AiOutlineCloseCircle
            className={classes.closeIcon}
            onClick={() => handleClose()}
          />
          <NavLink
            to={`/${version}/leaderboard`}
            // className={({ isActive }) =>
            //   isActive ? classes.active : classes.inactive
            // }
          >
            <img src={TrophyIcon} alt="leaderboard nav" />
          </NavLink>
        </div>
      </div>
      {/* {appleMode && (
        <div className={appleMode ? classes.rotateNotiice : null}>
          {" "}
          Please rotate your screen to Portrait Mode
        </div>
      )} */}

      <div
        className={!appleMode ? classes.appleGamePlay : classes.gamePlayWrap}
      >
        <iframe
          src={data?.filePath}
          height="100%"
          width="100%"
          frameborder="0"
        ></iframe>
      </div>
      {data?.badge === true && isNewBadge === true && (
        <NewBadgeCongratulation setIsNewBadge={setIsNewBadge} />
      )}
    </div>
  );
}
