import React from 'react'

// STYLES
import classes from "./loaders.module.css";

const Loader = () => {
  return (
    <div className={classes.loaderWrap}>
        <span className={classes.loader}></span>
    </div>
  )
}

export default Loader