import React, { useState, useEffect } from "react";
import { RiNotification2Fill } from "react-icons/ri";
import axios from "axios";

//SOUND
import notification from "../../assets/audio/notification.wav";

//STYLE
import classes from "./expiredNotificationCard.module.css";
import { useExpiredSubscription } from "../../hooks/fetch";

const ExpiredNotificationCard = () => {
  const userInfo = JSON.parse(sessionStorage.getItem("ccAuth"));
  const storedCount = sessionStorage.getItem("storedCount");
  const [count, setCount] = useState(5);

  const { data: expiredSub } = useExpiredSubscription(userInfo?.userPassport);

  useEffect(() => {
    if (count === 5 && storedCount !== "close") {
      const audio = new Audio(notification);
      audio.play()
    }

    const timer =
      count >= 0
        ? setTimeout(function () {
            const newCount = count - 1;
            setCount(newCount);
          }, 1000)
        : null;
    if (count < 1) {
      clearTimeout(timer);
      sessionStorage.setItem("storedCount", "close");
    }
  }, [count]);

  console.log(storedCount);

  return (
    <>
      <div
        className={
          count !== 0 && storedCount !== "close"
            ? classes.appear
            : classes.wrapper
        }
      >
        <div className={classes.textWrap}>
          <RiNotification2Fill className={classes.icon} />
          <div className={classes.text}>{expiredSub?.message}</div>
        </div>

        {/* <div className={classes.buttonWrap}>
        <button>Later</button>
        <button>Subscribe</button>
      </div> */}
      </div>
    </>
  );
};

export default ExpiredNotificationCard;
