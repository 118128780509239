import React from "react";
import { Avatar, Box, Paper, Stack, Typography } from "@mui/material";

export function CardRow({ key, sn, image, name, score, status, date, color }) {
  return (
    <Box key={key}>
      <Stack
        direction="row"
        sx={{
          gap: "5px",
          color: "white",
          fontSize: "0.8rem !important",
          alignItems: "center",
          backgroundColor: color,
          margin: "0 10px ",
          padding: "5px 10px",
          borderRadius: "20px",
        }}
      >
        <Typography variant="body2" sx={{ width: "15px" }}>
          {sn}
        </Typography>
        <Avatar src={image} sx={{ height: "22px", width: "22px" }}></Avatar>
        <Typography variant="body2" sx={{ width: "120px" }}>
          {name}
        </Typography>
        <Typography variant="body2" sx={{ width: "40px" }}>
          {score}
        </Typography>
        {/* <Typography sx={{ width: "55px" }}>{status}</Typography> */}
        <Typography>{date}</Typography>
      </Stack>
    </Box>
  );
}
function Leadercard({ title, options = [], color = "red" }) {
  console.log({ options });
  return (
    <Paper
      sx={{
        minHeight: "300px",
        maxWidth: "450px",
        position: "relative",
        // margin: "50px",
        border: `5px solid ${color}`,
        borderRadius: "15px",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: "-40px",
          left: "50%",
          backgroundColor: color,
          transform: "translate(-50%, 0%)",
          padding: "20px 20px",
          borderRadius: "15px",
          width: "100%",
        }}
      >
        <Typography align="center" variant="h6" sx={{ color: "white" }}>
          {title}
        </Typography>
      </Box>
      <Stack sx={{ marginTop: "90px", marginBottom: "20px", gap: "12px" }}>
        {/* <CardRow
          sn="2"
          image=""
          name="Oluwafemi Daramola"
          score="106"
          status="online"
          date="22/07/02003"
        /> */}
        {options?.map((data, index) => (
          <CardRow
            key={index}
            sn={data?.sn}
            image={data?.image}
            name={data?.name}
            score={data?.score}
            status={data?.status}
            date={data?.date}
            color={color}
          />
        ))}
      </Stack>
    </Paper>
  );
}

export default Leadercard;
